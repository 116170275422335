/**
 * `404` typically displays a 404 screen for paths that don't match one of the
 * pages built by Gatsby. However, we also use this component to render previews
 * of repeatable content type documents that have never been published. Unpublished
 * documents aren't yet processed by Gatsby so they get routed to this 404 template whenever
 * a content admin tries to preview them. To resolve this, we're checking to see
 * if a preview query param exists on the url, and if so load the appropriate template.
 */

import React, { useEffect, useState } from "react";
import qs from "query-string";
import { useAppState } from "@state/state";
import { use404Data } from "@staticQueries";
import * as DynamicQueries from "@dynamicQueries";
import { DefaultPageContainer as PageContainer } from "@layout";
import { HeaderWithProjects } from "@organisms";

const NotFoundPage = ({ pageContext, location, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  const [{ layout }, dispatch] = useAppState();
  const { heading, descriptor, cards, link } = use404Data();

  useEffect(() => {
    // set preview mode if preview data is detected
    const { "x-craft-live-preview": xCraftLivePreview } = qs.parse(
      location.search
    );
    if (xCraftLivePreview) {
      dispatch({ type: "setPreviewMode", previewMode: "loading" });
    } else {
      setLoaded(true);
    }
  }, []);

  if (layout.previewMode) {
    // Use the appropriate page query based on the url
    // Note we need to manually set a `pageContext` prop and supply
    // it with a slug which we're grabbing from the last segment of the url.
    // This is used in the preview query to fetch the appropriate entry
    const split = location.pathname.split("/");
    const firstSegment = split[1];
    const lastSegment = split[split.length - 1];
    const props = { ...rest, location, pageContext: { slug: lastSegment } };

    // set up queries here for preview
    if (split.length > 1) {
      switch (firstSegment) {
        case "project":
          return <DynamicQueries.ProjectQuery {...props} />;
        case "post":
          return <DynamicQueries.PostQuery {...props} />;
        default:
          try {
            return <DynamicQueries.PostQuery {...props} />;
          } catch (e) {
            console.log(e);
          }
          break;
      }
    }
    if (split.length) {
      switch (firstSegment) {
        case "contact":
          return <DynamicQueries.ContactQuery {...props} />;
        case "media":
          return <DynamicQueries.MediaQuery {...props} />;
        case "about":
          return <DynamicQueries.AboutQuery {...props} />;
        case "services":
          return <DynamicQueries.ServicesQuery {...props} />;
        case "energyServices":
          return <DynamicQueries.EnergyServicesQuery {...props} />;
        case "projectIndex":
          return <DynamicQueries.ProjectQuery {...props} />;
        case "postIndex":
          return <DynamicQueries.PostQuery {...props} />;
        case "page":
          return <DynamicQueries.PageQuery {...props} />;
      }
    }
    return <DynamicQueries.PageQuery {...props} />;
  }

  return (
    <PageContainer>
      <HeaderWithProjects {...use404Data()}/>
    </PageContainer>
  );
};

export default NotFoundPage;
