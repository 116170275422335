/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage, resolveBreaks, resolveMeta } from "@dataResolvers";
import { Projects as PageTemplate } from "@pageTemplates";

export const query = graphql`
query {
  craft {
    entries(section: "project") {
      ... on Craft_project_project_Entry {
        heroHeading
        heroDescription
        heroBackgroundImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        url
        projectCategories(limit: 6) {
          ...on Craft_projectCategories_Category {
            title
            slug
          }
        }
      }
    }
    entry(section: "projectIndex") {
      ... on Craft_projectIndex_projectIndex_Entry {
        #Meta
        title
        uid
        url
        slug
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        heroLabel
        heroHeading
      }
    }
  }
}
`;

const previewQuery = `
query {
  craft {
    entries(section: "project") {
      ... on Craft_project_project_Entry {
        heroHeading
        heroDescription
        heroBackgroundImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        url
        projectCategories(limit: 6) {
          ...on Craft_projectCategories_Category {
            title
            slug
          }
        }
      }
    }
    entry(section: "projectIndex") {
      ... on Craft_projectIndex_projectIndex_Entry {
        #Meta
        title
        uid
        url
        slug
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        heroLabel
        heroHeading
      }
    }
  }
}
`;

const dataResolver = ({ craft }) => {
  const {
    title,
    uid,
    url,
    slug,
    metaTitle,
    metaDescription,
    metaImage,
    heroHeading,
    heroLabel,
  } = craft.entry;

  return {
    meta: resolveMeta({
      title,
      metaTitle,
      metaImage,
      metaDescription,
      slug,
      url,
    }),
    hero: {
      label: heroLabel,
      heading: heroHeading,
    },
    projects: craft.entries.map(project => {
      return {
        heading: resolveBreaks(project.heroHeading),
        description: project.heroDescription,
        image: resolveImage(project.heroBackgroundImage),
        url: project.url,
        categories: project.projectCategories.map( category => {
          return {
            title: category.title,
            slug: category.slug,
          };
        }),
      };
    }),
  }
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
