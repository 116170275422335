/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage, resolveBreaks, resolveMeta } from "@dataResolvers";
import { About as PageTemplate } from "@pageTemplates";

export const query = graphql`
query {
  craft {
    entry(section: "about") {
      ... on Craft_about_about_Entry {
        #Meta
        title
        uid
        url
        slug
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        # Heading
        heroHeading
        heroDescription
        heroBackgroundImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        ourProcessProcesses {
          ... on Craft_ourProcessProcesses_process_BlockType {
            heading
            description
            processInformation {
              ... on Craft_processInformation_TableRow {
                label
                content
              }
            }
            color
          }
        }
        # Principles
        heading0
        descriptor0
        copy0
        linkField {
          url
          text
        }
        image0 {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        # Our Crew
        heading1
        descriptor1
        team {
          ... on Craft_team_teamMember_BlockType {
            teamMemberName
            jobTitle
            headshot {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            large
          }
        }
        # Join
        heading2
        descriptor2
        link0 {
          text
          url
        }
        jobs {
          ... on Craft_jobs_job_BlockType {
            jobTitle
            jobDescription
            jobPostingLink
          }
        }
      }
    }
  }
}
`;

const previewQuery = `
query {
  craft {
    entry(section: "about") {
      ... on Craft_about_about_Entry {
        #Meta
        title
        uid
        url
        slug
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        # Heading
        heroHeading
        heroDescription
        heroBackgroundImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        ourProcessProcesses {
          ... on Craft_ourProcessProcesses_process_BlockType {
            heading
            description
            processInformation {
              ... on Craft_processInformation_TableRow {
                label
                content
              }
            }
            color
          }
        }
        # Principles
        heading0
        descriptor0
        copy0
        linkField {
          url
          text
        }
        image0 {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        # Our Crew
        heading1
        descriptor1
        team {
          ... on Craft_team_teamMember_BlockType {
            teamMemberName
            jobTitle
            headshot {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            large
          }
        }
        # Join
        heading2
        descriptor2
        link0 {
          text
          url
        }
        jobs {
          ... on Craft_jobs_job_BlockType {
            jobTitle
            jobDescription
            jobPostingLink
          }
        }
      }
    }
  }
}
`;

const dataResolver = ({ craft }) => {
  const {
    title,
    uid,
    url,
    slug,
    metaTitle,
    metaDescription,
    metaImage,
    // Hero
    heroHeading,
    heroDescription,
    heroBackgroundImage,
    ourProcessProcesses,
    // Principles
    heading0,
    descriptor0,
    copy0,
    linkField,
    image0,
    // Crew 
    heading1,
    descriptor1,
    team,
    // Careers
    heading2,
    descriptor2,
    link0,
    jobs,
  } = craft.entry;

  return {
    meta: resolveMeta({
      title,
      metaTitle,
      metaImage,
      metaDescription,
      slug,
      url,
    }),
    hero: {
      heading: heroHeading,
      description: heroDescription,
      image: resolveImage(heroBackgroundImage),
    },
    purpose: ourProcessProcesses.map(process => {
      return {
        heading: resolveBreaks(process.heading),
        description: process.description,
        processInformation: process.processInformation.map(info => {
          return {
            label: info.label,
            content: resolveBreaks(info.content),
          };
        }),
        color: process.color,
      };
    }),
    principles: {
      heading: heading0,
      subhead: descriptor0,
      description: copy0,
      link: linkField,
      image: resolveImage(image0),
    },
    crew: {
      heading: heading1,
      description: descriptor1,
      team: team.map(member => {
        return {
          name: member.teamMemberName,
          title: member.jobTitle,
          headshot: resolveImage(member.headshot),
          large: member.large,
        };
      }),
    },
    careers: {
      heading: heading2,
      description: descriptor2,
      link: link0,
      jobs: jobs.map(job => {
        return {
          title: job.jobTitle,
          description: job.jobDescription,
          link: job.jobPostingLink,
        };
      }),
    },
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
